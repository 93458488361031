<template>
    <component :is="tag" @click="$emit('click')" :class="getClasses()" class="btn">
        <span class="btn__inner"><slot /></span>
    </component>
</template>

<script>
export default {
    name: 'AppButton',
    props: {
        theme: {
            type: String,
            default: 'white',
        },
        size: {
            type: String,
            default: 'md',
        },
        animation: {
            type: Boolean,
            default: true,
        },
        tag: {
            type: String,
            default: 'button',
        },
    },
    methods: {
        getClasses() {
            return [
                [`btn_size_${this.size}`, `btn_theme_${this.theme}`],
                {
                    btn_with_animation: this.animation,
                },
            ]
        },
    },
}
</script>
